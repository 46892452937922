export const timePicker = [
    { value: "12:00 PM", label: '12:00 PM' },
    { value: "12:15 PM", label: '12:15 PM' },
    { value: "12:30 PM", label: '12:30 PM' },
    { value: "12:45 PM", label: '12:45 PM' },
    { value: "1:00 PM", label: '1:00 PM' },
    { value: "1:15 PM", label: '1:15 PM' },
    { value: "1:30 PM", label: '1:30 PM' },
    { value: "1:45 PM", label: '1:45 PM' },
    { value: "2:00 PM", label: '2:00 PM' },
    { value: "2:15 PM", label: '2:15 PM' },
    { value: "2:30 PM", label: '2:30 PM' },
    { value: "2:45 PM", label: '2:45 PM' },
    { value: "3:00 PM", label: '3:00 PM' },
    { value: "3:15 PM", label: '3:15 PM' },
    { value: "3:30 PM", label: '3:30 PM' },
    { value: "3:45 PM", label: '3:45 PM' },
    { value: "4:00 PM", label: '4:00 PM' },
    { value: "4:15 PM", label: '4:15 PM' },
    { value: "4:30 PM", label: '4:30 PM' },
    { value: "4:45 PM", label: '4:45 PM' },
    { value: "5:00 PM", label: '5:00 PM' },
    { value: "5:15 PM", label: '5:15 PM' },
    { value: "5:30 PM", label: '5:30 PM' },
    { value: "5:45 PM", label: '5:45 PM' },
    { value: "6:00 PM", label: '6:00 PM' },
    { value: "6:15 PM", label: '6:15 PM' },
    { value: "6:30 PM", label: '6:30 PM' },
    { value: "6:45 PM", label: '6:45 PM' },
    { value: "7:00 PM", label: '7:00 PM' },
    { value: "7:15 PM", label: '7:15 PM' },
    { value: "7:30 PM", label: '7:30 PM' },
    { value: "7:45 PM", label: '7:45 PM' },
    { value: "8:00 PM", label: '8:00 PM' },
    { value: "8:15 PM", label: '8:15 PM' },
    { value: "8:30 PM", label: '8:30 PM' },
    { value: "8:45 PM", label: '8:45 PM' },
    { value: "9:00 PM", label: '9:00 PM' },
    { value: "9:15 PM", label: '9:15 PM' },
    { value: "9:30 PM", label: '9:30 PM' },
    { value: "9:45 PM", label: '9:45 PM' },
    { value: "10:00 PM", label: '10:00 PM' },
    { value: "10:15 PM", label: '10:15 PM' },
    { value: "10:30 PM", label: '10:30 PM' },
    { value: "10:45 PM", label: '10:45 PM' },
    { value: "11:00 PM", label: '11:00 PM' },
    { value: "11:15 PM", label: '11:15 PM' },
    { value: "11:30 PM", label: '11:30 PM' },
    { value: "11:45 PM", label: '11:45 PM' },
    { value: "12:00 AM", label: '12:00 AM' },
    { value: "12:15 AM", label: '12:15 AM' },
    { value: "12:30 AM", label: '12:30 AM' },
    { value: "12:45 AM", label: '12:45 AM' },
    { value: "1:00 AM", label: '1:00 AM' },
    { value: "1:15 AM", label: '1:15 AM' },
    { value: "1:30 AM", label: '1:30 AM' },
    { value: "1:45 AM", label: '1:45 AM' },
    { value: "2:00 AM", label: '2:00 AM' },
    { value: "2:15 AM", label: '2:15 AM' },
    { value: "2:30 AM", label: '2:30 AM' },
    { value: "2:45 AM", label: '2:45 AM' },
    { value: "3:00 AM", label: '3:00 AM' },
    { value: "3:15 AM", label: '3:15 AM' },
    { value: "3:30 AM", label: '3:30 AM' },
    { value: "3:45 AM", label: '3:45 AM' },
    { value: "4:00 AM", label: '4:00 AM' },
    { value: "4:15 AM", label: '4:15 AM' },
    { value: "4:30 AM", label: '4:30 AM' },
    { value: "4:45 AM", label: '4:45 AM' },
    { value: "5:00 AM", label: '5:00 AM' },
    { value: "5:15 AM", label: '5:15 AM' },
    { value: "5:30 AM", label: '5:30 AM' },
    { value: "5:45 AM", label: '5:45 AM' },
    { value: "6:00 AM", label: '6:00 AM' },
    { value: "6:15 AM", label: '6:15 AM' },
    { value: "6:30 AM", label: '6:30 AM' },
    { value: "6:45 AM", label: '6:45 AM' },
    { value: "7:00 AM", label: '7:00 AM' },
    { value: "7:15 AM", label: '7:15 AM' },
    { value: "7:30 AM", label: '7:30 AM' },
    { value: "7:45 AM", label: '7:45 AM' },
    { value: "8:00 AM", label: '8:00 AM' },
    { value: "8:15 AM", label: '8:15 AM' },
    { value: "8:30 AM", label: '8:30 AM' },
    { value: "8:45 AM", label: '8:45 AM' },
    { value: "9:00 AM", label: '9:00 AM' },
    { value: "9:15 AM", label: '9:15 AM' },
    { value: "9:30 AM", label: '9:30 AM' },
    { value: "9:45 AM", label: '9:45 AM' },
    { value: "10:00 AM", label: '10:00 AM' },
    { value: "10:15 AM", label: '10:15 AM' },
    { value: "10:30 AM", label: '10:30 AM' },
    { value: "10:45 AM", label: '10:45 AM' },
    { value: "11:00 AM", label: '11:00 AM' },
    { value: "11:15 AM", label: '11:15 AM' },
    { value: "11:30 AM", label: '11:30 AM' },
    { value: "11:45 AM", label: '11:45 AM' },
];

export const DIETARY = [
    { value: 'Dairy', label: 'Dairy' },
    { value: 'Diabetic', label: 'Diabetic' },
    { value: 'Eggs', label: 'Eggs' },
    { value: 'Fish', label: 'Fish' },
    { value: 'Gluten', label: 'Gluten' },
    { value: 'Gluten-free', label: 'Gluten-free' },
    { value: 'Kosher', label: 'Kosher' },
    { value: 'Lactose Intolerant', label: 'Lactose Intolerant' },
    { value: 'Lupin', label: 'Lupin' },
    { value: 'Milk', label: 'Milk' },
    { value: 'Mushrooms', label: 'Mushrooms' },
    { value: 'Mustard', label: 'Mustard' },
    { value: 'Nuts', label: 'Nuts' },
    { value: 'Peanuts', label: 'Peanuts' },
    { value: 'Seafood', label: 'Seafood' },
    { value: 'Sesame', label: 'Sesame' },
    { value: 'Shellfish', label: 'Shellfish' },
    { value: 'Shrimp', label: 'Shrimp' },
    { value: 'Soy', label: 'Soy' },
    { value: 'Walnuts', label: 'Walnuts' },
    { value: 'Vegan', label: 'Vegan' },
    { value: 'Vegetarian', label: 'Vegetarian' }
]

export const OCCASIONS = [
    { value: 'Anniversary', label: 'Anniversary' },
    { value: 'Baby Shower', label: 'Baby Shower' },
    { value: 'Bachelor Party', label: 'Bachelor Party' },
    { value: 'Bachelorette Party ', label: 'Bachelorette Party ' },
    { value: 'Birthday', label: 'Birthday' },
    { value: 'Bridal Shower', label: 'Bridal Shower' },
    { value: 'Business Celebration', label: 'Business Celebration' },
    { value: 'Business Meeting', label: 'Business Meeting' },
    { value: 'Date', label: 'Date' },
    { value: 'Engagement', label: 'Engagement' },
    { value: 'Graduation', label: 'Graduation' },
    { value: 'Holiday', label: 'Holiday' },
    { value: 'Honeymoon', label: 'Honeymoon' },
    { value: 'Job Promotion', label: 'Job Promotion' },
    { value: 'Other', label: 'Other' },
]

export const PARTYSIZE = [
    {
        "label": "2 guests",
        "value": "2 guests"
    },
    {
        "label": "3 guests",
        "value": "3 guests"
    },
    {
        "label": "4 guests",
        "value": "4 guests"
    },
    {
        "label": "5 guests",
        "value": "5 guests"
    },
    {
        "label": "6 guests",
        "value": "6 guests"
    },
    {
        "label": "7 guests",
        "value": "7 guests"
    },
    {
        "label": "8 guests",
        "value": "8 guests"
    },
    {
        "label": "9 guests",
        "value": "9 guests"
    },
    {
        "label": "10 guests",
        "value": "10 guests"
    },
    {
        "label": "11 guests",
        "value": "11 guests"
    },
    {
        "label": "12 guests",
        "value": "12 guests"
    },
    {
        "label": "13 guests",
        "value": "13 guests"
    },
    {
        "label": "14 guests",
        "value": "14 guests"
    },
    {
        "label": "15 guests",
        "value": "15 guests"
    },
    {
        "label": "16 guests",
        "value": "16 guests"
    },
    {
        "label": "17 guests",
        "value": "17 guests"
    },
    {
        "label": "18 guests",
        "value": "18 guests"
    },
    {
        "label": "19 guests",
        "value": "19 guests"
    },
    {
        "label": "20 guests",
        "value": "20 guests"
    },
    {
        "label": "21 guests",
        "value": "21 guests"
    },
    {
        "label": "22 guests",
        "value": "22 guests"
    },
    {
        "label": "23 guests",
        "value": "23 guests"
    },
    {
        "label": "24 guests",
        "value": "24 guests"
    },
    {
        "label": "25 guests",
        "value": "25 guests"
    },
    {
        "label": "26 guests",
        "value": "26 guests"
    },
    {
        "label": "27 guests",
        "value": "27 guests"
    },
    {
        "label": "28 guests",
        "value": "28 guests"
    },
    {
        "label": "29 guests",
        "value": "29 guests"
    },
    {
        "label": "30 guests",
        "value": "30 guests"
    },
    {
        "label": "31 guests",
        "value": "31 guests"
    },
    {
        "label": "32 guests",
        "value": "32 guests"
    },
    {
        "label": "33 guests",
        "value": "33 guests"
    },
    {
        "label": "34 guests",
        "value": "34 guests"
    },
    {
        "label": "35 guests",
        "value": "35 guests"
    },
    {
        "label": "36 guests",
        "value": "36 guests"
    },
    {
        "label": "37 guests",
        "value": "37 guests"
    },
    {
        "label": "38 guests",
        "value": "38 guests"
    },
    {
        "label": "39 guests",
        "value": "39 guests"
    },
    {
        "label": "40 guests",
        "value": "40 guests"
    },
    {
        "label": "41 guests",
        "value": "41 guests"
    },
    {
        "label": "42 guests",
        "value": "42 guests"
    },
    {
        "label": "43 guests",
        "value": "43 guests"
    },
    {
        "label": "44 guests",
        "value": "44 guests"
    },
    {
        "label": "45 guests",
        "value": "45 guests"
    },
    {
        "label": "46 guests",
        "value": "46 guests"
    },
    {
        "label": "47 guests",
        "value": "47 guests"
    },
    {
        "label": "48 guests",
        "value": "48 guests"
    },
    {
        "label": "49 guests",
        "value": "49 guests"
    },
    {
        "label": "50 guests",
        "value": "50 guests"
    },
    {
        "label": "51 guests",
        "value": "51 guests"
    },
    {
        "label": "52 guests",
        "value": "52 guests"
    },
    {
        "label": "53 guests",
        "value": "53 guests"
    },
    {
        "label": "54 guests",
        "value": "54 guests"
    },
    {
        "label": "55 guests",
        "value": "55 guests"
    },
    {
        "label": "56 guests",
        "value": "56 guests"
    },
    {
        "label": "57 guests",
        "value": "57 guests"
    },
    {
        "label": "58 guests",
        "value": "58 guests"
    },
    {
        "label": "59 guests",
        "value": "59 guests"
    },
    {
        "label": "60 guests",
        "value": "60 guests"
    },
    {
        "label": "61 guests",
        "value": "61 guests"
    },
    {
        "label": "62 guests",
        "value": "62 guests"
    },
    {
        "label": "63 guests",
        "value": "63 guests"
    },
    {
        "label": "64 guests",
        "value": "64 guests"
    },
    {
        "label": "65 guests",
        "value": "65 guests"
    },
    {
        "label": "66 guests",
        "value": "66 guests"
    },
    {
        "label": "67 guests",
        "value": "67 guests"
    },
    {
        "label": "68 guests",
        "value": "68 guests"
    },
    {
        "label": "69 guests",
        "value": "69 guests"
    },
    {
        "label": "70 guests",
        "value": "70 guests"
    },
    {
        "label": "71 guests",
        "value": "71 guests"
    },
    {
        "label": "72 guests",
        "value": "72 guests"
    },
    {
        "label": "73 guests",
        "value": "73 guests"
    },
    {
        "label": "74 guests",
        "value": "74 guests"
    },
    {
        "label": "75 guests",
        "value": "75 guests"
    },
    {
        "label": "76 guests",
        "value": "76 guests"
    },
    {
        "label": "77 guests",
        "value": "77 guests"
    },
    {
        "label": "78 guests",
        "value": "78 guests"
    },
    {
        "label": "79 guests",
        "value": "79 guests"
    },
    {
        "label": "80 guests",
        "value": "80 guests"
    },
    {
        "label": "81 guests",
        "value": "81 guests"
    },
    {
        "label": "82 guests",
        "value": "82 guests"
    },
    {
        "label": "83 guests",
        "value": "83 guests"
    },
    {
        "label": "84 guests",
        "value": "84 guests"
    },
    {
        "label": "85 guests",
        "value": "85 guests"
    },
    {
        "label": "86 guests",
        "value": "86 guests"
    },
    {
        "label": "87 guests",
        "value": "87 guests"
    },
    {
        "label": "88 guests",
        "value": "88 guests"
    },
    {
        "label": "89 guests",
        "value": "89 guests"
    },
    {
        "label": "90 guests",
        "value": "90 guests"
    },
    {
        "label": "91 guests",
        "value": "91 guests"
    },
    {
        "label": "92 guests",
        "value": "92 guests"
    },
    {
        "label": "93 guests",
        "value": "93 guests"
    },
    {
        "label": "94 guests",
        "value": "94 guests"
    },
    {
        "label": "95 guests",
        "value": "95 guests"
    },
    {
        "label": "96 guests",
        "value": "96 guests"
    },
    {
        "label": "97 guests",
        "value": "97 guests"
    },
    {
        "label": "98 guests",
        "value": "98 guests"
    },
    {
        "label": "99 guests",
        "value": "99 guests"
    },
    {
        "label": "100 guests",
        "value": "100 guests"
    }
]









