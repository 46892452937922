import React, { useEffect, useState } from 'react';
import './schedule-reservation.scss'
import { useParams } from 'react-router-dom';

import { getAllRestaurantAdmins, getAllRestaurantHosts, getRestaurantByUniqueId, images } from '../../../commonConfig';
import Loader from '../../../components/loader/loader';
import Footer from '../../../components/molecules/Footer/Footer';
import MakeReservation from '../make-reservation/make-reservation';

const ScheduleReservation = () => {
  const [restaurantData, setRestaurantData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [isReserveEnabled, setIsReserveEnabled] = useState<boolean>(false)
  const [membersLimit, setMembersLimit] = useState<any>();
  const [seatingList, setSeatingList] = useState<any[]>([]);
  const [defaultSeating, setDefaultSeating] = useState<any[]>([])
  const [resAdmins, setResAdmins] = useState<any[]>([]);
  const [resHosts, setResHosts] = useState<any[]>([]);

  const [date, setDate] = useState<any>({
    maxDate: '',
    minDate: ''
  })

  const urlParams = useParams()
  const uniqueID = urlParams.uniqId

  useEffect(() => {
    if (uniqueID !== undefined) {
      getRestaurantByUniqueId(uniqueID).then((res) => {
        if (res !== undefined) {
          setRestaurantData(res);
          setIsReserveEnabled(res?.product)
          if (res?.reservation_settings) {
            var reserveSeating = res?.reservation_settings?.seating;
            if (reserveSeating && reserveSeating.length) {
              var seating: any[] = [];
              setDefaultSeating(reserveSeating)
              for (let i = 0; i < reserveSeating.length; i++) {
                seating.push({
                  value: reserveSeating[i].seatingType,
                  label: reserveSeating[i].seatingType,
                  deposit: reserveSeating[i].deposit,
                  amount: reserveSeating[i].amount,
                  members: reserveSeating[i].member,
                  min_member: reserveSeating[i]?.min_member
                })
              }
              setSeatingList(seating)
            }
            setMembersLimit(res?.reservation_settings?.members);
            let maxDate = new Date();
            maxDate.setDate(maxDate.getDate() + parseInt(res.reservation_settings.maxDayBefore))

            let minDate = new Date();
            minDate.setDate(minDate.getDate() + parseInt(res.reservation_settings.minDayBefore))
            setDate({
              maxDate: maxDate,
              minDate: minDate
            })
          }
          getAllRestaurantAdmins(res.id).then((res) => setResAdmins(res)).catch((err) => console.log('err :>> ', err))
          getAllRestaurantHosts(res.id).then((res) => setResHosts(res)).catch((err) => console.log('err :>> ', err))
          setLoading(false)
        } else {
          setLoading(false);
          setRestaurantData(undefined)
        }
      })
    }

  }, [uniqueID]);


  return (
    <div>
      {loading ? <Loader /> : <>
        {restaurantData !== undefined ?
          <MakeReservation
            restaurantData={restaurantData}
            date={date}
            isReserveEnabled={isReserveEnabled}
            membersLimit={membersLimit}
            seatingList={seatingList}
            setLoading={setLoading}
            resAdmins={resAdmins}
            resHosts={resHosts}
            setSeatingList={setSeatingList}
            defaultSeating={defaultSeating}
          />
          :
          <div className="error-main-container">
            <div className="error-container">
              <h1 className="error-message">
                Oh no! <br />
                We only found crumbs.
              </h1>
              <p>
                {" "}
                We didn’t find a restaurant based on the URL you’ve entered. Check
                that you have the correct web address.
              </p>
              <div className="error-img">
                <img src={images.IMG_ERROR} className="error-image" alt="icon" />
              </div>
            </div>
            <div style={{ bottom: "0px" }}>
              <Footer />
            </div>
          </div>}
      </>}
    </div>
  )
}

export default ScheduleReservation